import React from 'react';
// import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import "../../styles.css";
import './App.css';
import { LanguageProvider } from '../../lib/contexts/Localization';
// import { withTranslation } from 'react-i18next';

import Dashboard from '../Dashboard/Dashboard';
import Redirection from '../Redirection/Redirection';

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const migration = urlParams.get('migration');
  return (
    <LanguageProvider>
      {migration ? <Redirection /> : <Dashboard />}
    </LanguageProvider>
  );
}
 
export default App;