import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ENG: {
    "user claims": "user claims"
  },
  ESP: {
    "user claims": "reclamos de usuario"
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "EN",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      //   format: function(value, format, lng) {
      //     if(value instanceof Date) return moment(value).format(format);
      //     return value;
      // }
    }
  });
// i18n.on('languageChanged', function(lng) {
//   console.log('test');
//   moment.locale('es');
// });

export default i18n;