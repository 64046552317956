import { useContext } from 'react';
import { LanguageContext } from './Provider';

const useTranslation = () => {
  const languageContext = useContext(LanguageContext);
  // const esJson = transArr.map(tr => ({[tr['Suggested English'] || tr['English Version']]: tr.Spanish})).reduce((a, v) => ({ ...a, [Object.keys(v)[0]]: Object.values(v)[0]}), {});


  if (languageContext === undefined) {
    throw new Error('Language context is undefined');
  }

  return languageContext;
};

export default useTranslation;
