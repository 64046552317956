import { EN } from './languages';

const publicUrl = process.env.PUBLIC_URL;

export const LS_KEY = 'idm_poc_language';

export const fetchLocale = async (locale) => {
  const response = await fetch(`${publicUrl}/locales/${locale}.json`);
  const data = await response.json();
  return data;
};

export const getLanguageCodeFromLS = () => {
  try {
    const codeFromStorage = localStorage.getItem(LS_KEY);
    const urlParams = new URLSearchParams(window.location.search);
    const codeFromURL = urlParams.get('lang');
    

    return codeFromURL || codeFromStorage || EN.locale;
  } catch {
    return EN.locale;
  }
};
