/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
    OutlinedInput,
    Grid,
    Button,
    InputLabel,
} from '@material-ui/core';
import styles from './Redirection.module.scss';

const Redirection = () => {
    const styledDiv = {
        display: 'flex',
        justifyContent: 'center',
        background: '#F4F4F4',
        height: '100vh',
    };

    const [formData, setFormData] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const userid = urlParams.get('migrateUser');

    return (
        <div style={styledDiv}>
            <div className={styles.wrapper}>
                <h4 className={styles.middle_heading}>Redirection POC</h4>
                <h4 className={styles.middle_sub_heading}>
                    Enter your login information:
                </h4>
                <form
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <InputLabel
                        className={styles.label}
                        htmlFor="userid"
                        required
                        aria-label="userid"
                    >
                        User ID
                    </InputLabel>
                    <OutlinedInput
                        id="userid"
                        name="userid"
                        autoFocus
                        disabled
                        // onChange={(e) => setFormData({
                        //     ...formData,
                        //     [e.target.name]: e.target.value,
                        // })}
                        placeholder={'Enter User Id'}
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                        autoComplete="off"
                        value={userid}
                    />
                    <InputLabel
                        className={styles.label}
                        htmlFor="userpassword"
                        required
                        aria-label="userpassword"
                    >
                        Password
                    </InputLabel>
                    <OutlinedInput
                        id="userpassword"
                        name="userpassword"
                        type="password"
                        autoFocus
                        onChange={(e) => setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                        })}
                        placeholder={'Enter Password'}
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                        autoComplete="off"
                        value={formData.userpassword}
                    />
                </form>
                <Grid className={styles.btn_wrapper}>
                    <Button type="button"
                        variant="outlined"
                        className={styles.sub_text_btn}
                        onClick={() => console.log(formData)}>
                        Submit
                    </Button>
                </Grid>
            </div>
        </div>
    );
};

export default Redirection;
