/* eslint-disable */
import React from 'react';
import { Backdrop, CircularProgress, Grid, Typography } from '@material-ui/core';

import OverlayLoader from '../../images/overlay-loader.gif';
import { PocLoaderStyles } from './PocLoaderStyles';
import './PocLoaderCSS.css';

const useStyles = PocLoaderStyles();

const PocLoader = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      role="presenter"
      open={open}
      aria-hidden="true"
      aria-disabled="true"
    >
      <Grid container className={classes.center_style}>
        <Grid item xs={12} className={classes.center_style}>
          {/* <img
            src={OverlayLoader}
            className={classes.loaderStyle}
            alt="Loading..."
          /> */}
          {/* <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
          </div> */}
          {/* <div class="ripple-loader">
            <div></div>
            <div></div>
          </div> */}
          <div className="donut" />
          {/* <CircularProgress size={65} /> */}
        </Grid>
        <Grid item xs={12} className={classes.center_style}>
          <Typography className={classes.loading}>Loading ...</Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default PocLoader;
