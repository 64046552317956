/* eslint-disable */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
// import { useTranslation, withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Title from './Title';

// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
import PocLoader from '../PocLoader/PocLoader';
import Orders from './Orders';
import { Badge, Button, FormControl, IconButton, InputLabel, MenuItem, Select, useRadioGroup } from '@material-ui/core';
import { useTranslation } from '../../lib/contexts/Localization';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginTop: theme.spacing(2)
  },
  fixedHeight: {
    height: 240,
  },
}));

const  Dashboard = ({ i18n }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const accessToken = urlParams.get('accessToken');
  const username = urlParams.get('username');
  const { t, setLanguage, currentLanguage } = useTranslation();
  const [languageString, setLanguageString] = useState(currentLanguage.locale !== 'es-ES' ? 'ESP' : 'ENG');
  // /?token=NmNiZTAyYWItMGI0YS00YmIxLTkyMjQtMTA4MWExMWYzZDQ5
  const [accessTokenSec, setAccessTokenSec] = useState("");
  // const [userInfo, setUserInfo] = useState('');
  const [bothURL, setBothURL] = useState({ caseA: '', caseB: '' });
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstname: 'Rakesh',
    familyname: 'Pai',
    username: 'rakesh321',
    cisnumber: '66125467',
    lastlogintimestamp: '1632139443545',
  });
  const [formData, setFormData] = useState(''); 
  const [dropDownList, setDropDownList] = useState([
    {
      id: 1,
      name: 'Chat Bot',
    },
    {
      id: 2,
      name: 'Digital Sales',
    },
  ]);
  const objectToQueryString = queryParameters => {
    return queryParameters
      ? Object.entries(queryParameters).reduce(
          (queryString, [key, val], index) => {
            const symbol = queryString.length === 0 ? '?' : '&';
            queryString +=
              typeof val === 'string' ? `${symbol}${key}=${encodeURIComponent(val)}` : '';
            return queryString;
          },
          ''
        )
      : '';
  };

  const onFormChange1 = (e) => {
    // case a: 
    const redirectUrl = new URL(process.env.REACT_APP_COMEBACK_DESTINATION);
    redirectUrl.searchParams.set('intentTrigger', 'CB_AccountBalanceIntent_three_one');
    redirectUrl.searchParams.set('custName', userInfo.username || 'Alex');
    redirectUrl.searchParams.set('dc_language', currentLanguage.locale === 'en-US' ? 'EN' : 'ES');
    // window.open(
    //   redirectUrl.toString(),
    //   '_self'
    // );
    // case  b:
    // to https://idm-ui-dev.orientalbank.com/?response_type=token&client_id=bsg5u3ioj4m4a90mcan0s7bdp&redirect_uri= encoded_target_url(https://dc-chatbot-dev.orientalbank.com/?dc_language=EN&intentTrigger=CB_AccountBalanceIntent_three_one)  
    const redirectUrlCaseB = new URL(process.env.REACT_APP_IDM_CLIENT_APP);
    redirectUrlCaseB.searchParams.set('response_type', 'token');
    redirectUrlCaseB.searchParams.set('client_id', 'bsg5u3ioj4m4a90mcan0s7bdp');
    redirectUrlCaseB.searchParams.set('redirect_uri', encodeURI(redirectUrl.toString()));
    // redirectUrlCaseB.searchParams.set('dc_language', currentLanguage.locale === 'en-US' ? 'EN' : 'ES');
    console.log('redirectUrlCaseB');
    console.log(encodeURI(redirectUrl.toString()));
    console.log(redirectUrlCaseB.toString());
    setBothURL({ caseA: redirectUrl, caseB: redirectUrlCaseB });
    // window.open(
    //   redirectUrlCaseB.toString(),
    //   '_self'
    // );
    setFormData(e.target.value);
    axios.post(`${process.env.REACT_APP_IDM_API_REDIRECT}sso`, {
      accessToken,
      "appClientId" : process.env.REACT_APP_CLIENT_ID,
      "clientSecret" : process.env.REACT_APP_CLIENT_SECRET,
      "intent" : "CB_AccountBalanceIntent_three_one",
      "deepLink" : process.env.REACT_APP_COMEBACK_DESTINATION,
      "sessionState" : "active"
    },
    {
      headers: {
        appid : 'fis'
      }
    }
    ).then((res) => {
      setLoading(false);
      // "targetUrl": https://dc-chatbot-dev.orientalbank.com/?dc_language=EN&&intentTrigger=CB_AccountBalanceIntent_three_one&custName=rakesh321
      console.log(res.data);
      const redirectUrl = new URL(process.env.REACT_APP_COMEBACK_DESTINATION);
      redirectUrl.searchParams.set('intentTrigger', 'CB_AccountBalanceIntent_three_one');
      redirectUrl.searchParams.set('custName', userInfo.username || 'Alex');
      redirectUrl.searchParams.set('dc_language', currentLanguage.locale === 'en-US' ? 'EN' : 'ES');
      window.open(
        res?.data?.targetUrl || redirectUrl.toString(),
        '_self'
      );
    }).catch((err) => {
      setLoading(false);
      console.log("Error here: ", err);
    })
  };

  const onFormChange = (e) => {
    console.log(e.target.value);
    setFormData(e.target.value);
    switch (e.target.value) {
      // 2 for sales and 1 for comms
      case 2:
      case 1:
        // chatbot redirection
        setLoading(true);
        axios.post(`${process.env.REACT_APP_IDM_API_REDIRECT}sso`, {
          accessToken,
          "appClientId" : process.env.REACT_APP_CLIENT_ID,
          "clientSecret" : process.env.REACT_APP_CLIENT_SECRET,
          "intentTrigger" : e.target.value === 1 ? "CB_AccountBalanceIntent_three_one" : "DS_OpenAccount",
          "dc_language" : "EN",
          "applicationCode" : "OFG_Sales",
          "locale": "PR"
        },
        {
          headers: {
            appid : 'fis'
          }
        }
        ).then((res) => {
          // setLoading(false);
          // 200 Response
        //   {
        //     "dc_language": "EN",
        //     "sessionId": "1774e8dd-e9dc-44ee-b85b-7f4a64052df1",
        //     "intentTrigger": "CB_AccountBalanceIntent_three_one",
        //     "locale": "PR",
        //     "redirectUrl": "OFG_Chatbot",
        //     "applicationCode": "OFG_Chatbot"
        // }

          console.log(res.data);
          console.dir(res.data);
          console.log('redirecting to...');
          if (e.target.value === 2) {
            console.log(
              `https://idm-ui-sandbox.orientalbank.com/${objectToQueryString(
                res.data
              )}`.toString()
            );
            window.open(
              `https://idm-ui-sandbox.orientalbank.com/${objectToQueryString(
                res.data
              )}`.toString(),
              '_self'
            );
          } else {
            console.log('https://dc-chatbot-dev.orientalbank.com/');
            window.open('https://dc-chatbot-dev.orientalbank.com/', '_self');
          }
          // console.log(`https://idm-ui-sandbox.orientalbank.com/${objectToQueryString(res.data)}`.toString());
        }).catch((err) => {
          setLoading(false);
          console.log("Error here: ", err);
        })
        break;
      case 1:
        // sales redirection
        window.open(
          'https://ds-ui-assets-qa.orientalbank.com/pr/en/form/ira-facil/contact-info',
          '_self'
        );
        break;
      default:
        break;
    }
    // case a: 
    // const redirectUrl = new URL(process.env.REACT_APP_COMEBACK_DESTINATION);
    // redirectUrl.searchParams.set('intentTrigger', 'CB_AccountBalanceIntent_three_one');
    // redirectUrl.searchParams.set('custName', userInfo.username || 'Alex');
    // redirectUrl.searchParams.set('dc_language', currentLanguage.locale === 'en-US' ? 'EN' : 'ES');
    // // window.open(
    // //   redirectUrl.toString(),
    // //   '_self'
    // // );
    // // case  b:
    // // to https://idm-ui-dev.orientalbank.com/?response_type=token&client_id=bsg5u3ioj4m4a90mcan0s7bdp&redirect_uri= encoded_target_url(https://dc-chatbot-dev.orientalbank.com/?dc_language=EN&intentTrigger=CB_AccountBalanceIntent_three_one)  
    // const redirectUrlCaseB = new URL(process.env.REACT_APP_IDM_CLIENT_APP);
    // redirectUrlCaseB.searchParams.set('response_type', 'token');
    // redirectUrlCaseB.searchParams.set('client_id', 'bsg5u3ioj4m4a90mcan0s7bdp');
    // redirectUrlCaseB.searchParams.set('redirect_uri', encodeURI(redirectUrl.toString()));
    // // redirectUrlCaseB.searchParams.set('dc_language', currentLanguage.locale === 'en-US' ? 'EN' : 'ES');
    // console.log('redirectUrlCaseB');
    // console.log(encodeURI(redirectUrl.toString()));
    // console.log(redirectUrlCaseB.toString());
    // setBothURL({ caseA: redirectUrl, caseB: redirectUrlCaseB });
    // window.open(
    //   redirectUrlCaseB.toString(),
    //   '_self'
    // );
    // setFormData(e.target.value);
    
  };

  function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'© '}
        {new Date().getFullYear()}
        {' '}
        {t('orientalbank.com. All Rights Reserved. FDIC Member.')}
      </Typography>
    );
  }

  // useEffect(() => {
  //   // setLoading(true);
  //   axios.post(`${process.env.REACT_APP_IDM_API}mock/customer/info`, {
  //     // username : username,
  //     // password: "",
  //     idToken: token,
  //     // clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  //     // appClientId: process.env.REACT_APP_CLIENT_ID
  //     // appClientId : "2koe4fs5d5jbdin8o82n3on90f"
  //   },
  //   // {
  //   //   headers: {
  //   //     "Content-Type": "application/json; charset=UTF-8",
  //   //     "Accept": "*/*",
  //   //     "AppId": 'D1 Web',
  //   //   }
  //   // }
  //   ).then((res) => {
  //     setLoading(false);
  //     console.log(res.data);
  //     // setUserInfo(parseJwt(res.data?.idToken));
  //     setUserInfo(res.data)
  //   }).catch((err) => {
  //     setUserInfo({"firstname":"Rakesh","familyname":"Pai","username":"rakesh321","cisnumber":"66125467","lastlogintimestamp":"1632139443545"});
  //     setLoading(false);
  //     console.log("Error here: ", err);
  //   })
  //   // axios.get('https://api-dt-dev.orientalbank.com/ob-idmapi/selfservice/userinfo', {
  //   //   headers: {
  //   //     Authorization: accessToken,
  //   //     Accept: 'application/json',
  //   //     'Content-Type': 'application/json',
  //   //   },
  //   // })
  //   // .then(res => setUserInfo(res.data))
  //   // .catch(console)
  // }, [])

  useEffect(() => {
    setUserInfo(parseJwt(accessToken));
  }, [accessToken]);

  const changeLang = () => {
    if (languageString === 'ESP') {
      setLanguageString('ENG');
      setLanguage({ locale: 'es-ES', language: 'Español', code: 'es-ES' });
    } else {
      setLanguageString('ESP');
      // setLanguage('EN');
      setLanguage({ locale: 'en-US', language: 'English', code: 'en' });
      // setLanguage("ENG");
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {t('FIS D1 Dashboard')}
          </Typography>
          <Button color="basic" variant="contained" size="small" onClick={changeLang}>
            {currentLanguage.locale === 'es-ES' ? 'ESP' : 'ENG'}
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container alignItems="flex-end">
            <Grid sm={9}>
              <Typography component="h2" variant="h6" color="">
                {t('Welcome to D1 Dashboard. You have been successfully redirected to dashboard')}
              </Typography>
            </Grid>
          
            <Grid sm={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-chatbox-label">Selection Dropdown</InputLabel>
                <Select
                  labelId="demo-simple-chatbox-label"
                  id="chatbot"
                  name="chatbot"
                  // variant="outlined"
                  fullWidth
                  role="listbox"
                  // aria-label={'Select chatbot dropdown Required'}
                  onChange={onFormChange}
                  value={formData}
                  classes={{
                    icon: 'select-icon',
                  }}
                >
                  {/* <MenuItem value="" role="option">
                    Chatbot
                  </MenuItem> */}
                  {dropDownList?.map((eachItem) => (
                    <MenuItem key={eachItem.id} role="option" value={eachItem.id}>
                      {eachItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {/* <Orders /> */}
                {/* <pre>{JSON.stringify(bothURL, null, 2)}</pre> */}
                <Title>{t('User Claims')}</Title>
                  <Table size="small">
                    {Object.entries(userInfo).map(([key, value]) => (<TableRow>
                      <TableHead>{key}</TableHead>
                      <TableCell>{value}</TableCell>
                    </TableRow>))}
                  </Table>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
      <PocLoader open={loading} />
    </div>
  );
};

export default Dashboard;
